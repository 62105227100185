<script>
	// Free little ring loader from https://loading.io/css/
	export let style = '';
	export let absolute = false;
</script>

<div class="lds-dual-ring" class:absolute {style}></div>

<style>
	.lds-dual-ring {
		display: inline-block;
		width: 29px;
		height: 29px;
	}
	.lds-dual-ring:after {
		content: ' ';
		display: block;
		width: 29px;
		height: 29px;
		margin: 0px;
		border-radius: 50%;
		border: 4px solid #fff;
		border-color: #fff transparent #fff transparent;
		box-sizing: border-box;
		animation: lds-dual-ring 1.2s linear infinite;
	}
	.lds-dual-ring.absolute {
		position: absolute;
		top: 6px;
		left: 38%;
		left: calc(50% - 15px);
	}
	@keyframes lds-dual-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>
